body, .bs-section {
    background-color: transparent !important;
}

/* Non fa l'animazione della barra di caricamento quando viene scaricato unity */
.progress-bar-no-transition span {
    transition: transform 0s linear 0s !important;
}

.browser-source {
    width: 1920px;
    height: 1080px;
    position: relative;
}

.unity-div {
    width: 1920px;
    height: 1080px;
    position: absolute;
}

.browser-source-circle-angle {
    position: absolute;
    z-index: 2;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: white;
}

.browser-source-border-black {
    position: absolute;
    width: 50px;
    height: 50px;
}

.browser-source-top-right-border-black {
    top: 0;
    right: 0;
    border-top: 10px solid black;
    border-right: 10px solid black;
}

.browser-source-top-left-border-black {
    top: 0;
    left: 0;
    border-top: 10px solid black;
    border-left: 10px solid black;
}

.browser-source-bottom-right-border-black {
    bottom: 0;
    right: 0;
    border-bottom: 10px solid black;
    border-right: 10px solid black;
}

.browser-source-bottom-left-border-black {
    bottom: 0;
    left: 0;
    border-bottom: 10px solid black;
    border-left: 10px solid black;
}

.browser-source-border-white {
    position: absolute;
    width: 200px;
    height: 200px;
}

.browser-source-top-border-white {
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    border-top: 10px solid white;
}

.browser-source-left-border-white {
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    border-left: 10px solid white;
}

.browser-source-right-border-white {
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    border-right: 10px solid white;
}

.browser-source-bottom-border-white {
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    border-bottom: 10px solid white;
}

.mass-attack-timer-bar {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 5;
}

.mass-attack-bar-container {
    /*fixed position*/
    position: absolute;
    width: 276px;
    height: 100px;
}

.mass-attack-bar-logo {
    z-index: 1;
}

.mass-attack-bar-loading {
    position: absolute;
    left: 94px;
    top: 15px;
}

.mass-attack-bar-title {
    position: absolute;
    left: 82px;
    top: 13px;
}

.mass-attack-bar-progress {
    position: relative;
    top: 5px;
    left: -5px;
}

.absolute-center {
    width: fit-content;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.mass-attack-starts-in-text {
    font-family: 'Luckiest Guy', cursive !important;
    animation: mass-attack-starts-in-animation 1s ease-in-out;
}

.mass-attack-waiting-time {
    font-family: 'Luckiest Guy', cursive !important;
    animation: mass-attack-waiting-time-animation 1s ease-in-out;
    width: fit-content;
    opacity: 0;
}

.mass-attack-waiting-time-invisible {
    width: fit-content;
    opacity: 0;
}

@keyframes mass-attack-starts-in-animation {
    0% {
        transform: scale(0.2);
        opacity: 0;
    }

    100% {
        transform: scale(1);
        opacity: 1;
    }
}

@keyframes mass-attack-waiting-time-animation {
    0% {
        transform: scale(0.2) rotate(75deg);
        opacity: 0;
    }

    20% {
        opacity: 1;
    }

    50% {
        opacity: 1;
        transform: scale(1) rotate(0deg);
    }

    80% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}