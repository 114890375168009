.home-page-1 {
    padding-top: 80px;
    height: 100vh;
    background-size: 100%;
    background-position: bottom;
    background-repeat: no-repeat;
}

.home-page-1-image-container {
    display: flex;
    justify-content: center;
}

.home-page-1-image-container img {
    width: 90%;
    border-radius: 20px;
    box-shadow: rgba(0, 0, 0, 0.19) 0 10px 20px, rgba(0, 0, 0, 0.23) 0 6px 6px;
    animation: float-rotate 6s ease-in-out infinite;
}

.home-page-2 {
    padding-top: 80px;
    background-color: #f5f2fc;
    color: black;
    background-size: cover;
    background-position: top;
    background-repeat: no-repeat;
}

.title-text-container-adjuster {
    padding-inline: 12px;
    position: relative;
    transform: translateY(calc(-50% - 12px));
    width: fit-content;
    background: linear-gradient(180deg, #f5f2fc 20%, #ffffff 100%);
    color: white;
    z-index: 1;
}

.text-container {
    background: #ffffff;
    padding: 0px 12px 12px 12px;
    border-radius: 40px;
    border: 4px solid #9172c8;
    /*box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;*/
}

.text-container div {
    font-family: BalsamiqSans-Regular, serif;
}

.image-shadow {
    -webkit-filter: drop-shadow(3px 3px 3px #00000066);
    filter: drop-shadow(3px 3px 3px #00000066);
}

.float-animation {
    animation: float 10s ease-in-out infinite;
}

.embed-container {
    position: relative;
    padding-bottom: 56.25%;
    height: 0;
    overflow: hidden;
    max-width: 100%;
}

.embed-container iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 30px;
}

@keyframes float {
    0% {
        transform: translatey(0px);
    }
    50% {
        transform: translatey(10px);
    }
    100% {
        transform: translatey(0px);
    }
}

@keyframes float-rotate {
    0% {
        transform: translatey(0px) rotate(5deg);
    }
    50% {
        transform: translatey(10px) rotate(5deg);
    }
    100% {
        transform: translatey(0px) rotate(5deg);
    }
}

@media only screen and (max-width: 599px) {
    .home-page-1 {
        text-align: center;
    }

    .app-title {
        font-size: 3.1rem !important;
    }
}

@media only screen and (max-width: 350px) {
    .home-page-1-image-container {
        display: none;
    }
}

@media only screen and (max-height: 699px) {
    .home-page-1-image-container {
        display: none;
    }
}


@media only screen and (max-width: 899px) {
    .home-page-1-image-container img {
        width: 80% !important;
    }
}