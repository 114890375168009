img {
    -webkit-user-drag: none;
}

a {
    color: white;
}

.app-section {
    background-image: linear-gradient(180deg, #6d26ec 0%, #bc9aff 50%);
}

.wave-top {
    position: absolute;
    width: 100%;
    height: 100vh;
    background-size: 100%;
    background-position: 0 98px;
    background-repeat: no-repeat;
}

.footer {
    background: #37333F;
    margin-top: 96px;
    color: #B2B4BF;
}

.cool-text-shadow {
    text-shadow: rgb(1 0 125 / 38%) 3px 3px 0, -1px -1px 0 rgb(1 0 125), 0 -1px 0 rgb(1 0 125), 1px -1px 0 rgb(1 0 125), 1px 0 0 rgb(1 0 125), 1px 1px 0 rgb(1 0 125), 0 1px 0 rgb(1 0 125), -1px 1px 0 rgb(1 0 125), -1px 0 0 rgb(1 0 125)
}

.cool-text-shadow-red {
    text-shadow: #991E15 3px 3px 0, -1px -1px 0 #991E15, 0 -1px 0 #991E15, 1px -1px 0 #991E15, 1px 0 0 #991E15, 1px 1px 0 #991E15, 0 1px 0 #991E15, -1px 1px 0 #991E15, -1px 0 0 #991E15
}

.text-title-shadow {
    text-shadow: #390022 -1px -1px 0, #390022 1px -1px 0, #390022 -1px 1px 0, #390022 1px 1px 0;
}

.image-in-subtitle {
    position: relative;
    top: 6px;
    left: 6px;
}

@media only screen and (max-width: 599px) {
    .footer {
        text-align: center;
        padding-block: 8px;
    }
}